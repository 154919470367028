import React from 'react';
import Spline from '@splinetool/react-spline';

export default function App() {
  // Function to detect mobile devices
  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  // Choose the Spline scene based on the device type
  const sceneUrl = isMobile()
    ? 'https://prod.spline.design/uDvTTkEMEaRUfjZL/scene.splinecode' // Mobile scene
    : 'https://prod.spline.design/6Q7xgBmMMMIpp2BH/scene.splinecode'; // Desktop scene

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <Spline scene={sceneUrl} />
    </div>
  );
}